
import { utils } from 'near-api-js';
import { storeToRefs } from 'pinia';
import { defineComponent, onMounted, computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useEventsStore } from '@/entities/events/eventsStore';

export const EventsManagementStaffPage = defineComponent({
  name: 'EventsManagementStaffPage',
  setup() {
    const route = useRoute();
    const eventsStore = useEventsStore();
    const event = ref();

    const id = route.params.id;

    onMounted(async () => {
      eventsStore.getOwnedEvents();

      event.value = await eventsStore.getOwnedEventById(id);
    });

    const removeStaff = async function (id: any, item: any) {
      await eventsStore.removeStaff(id, item);
      return true;
    };

    const addStaff = async function (value: any) {
      await eventsStore.addStaff(id, value);
      return true;
    };

    return { id, event, utils, removeStaff, addStaff };
  },
});
export default EventsManagementStaffPage;
