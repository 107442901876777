
import { defineComponent } from 'vue';

import NearConnectButton from '@/features/NearConnectButton.vue';

export const ConnectWalletPage = defineComponent({
  name: 'ConnectWalletPage',
  components: { NearConnectButton },
});
export default ConnectWalletPage;
