
import { Modal } from 'ant-design-vue';
import { storeToRefs } from 'pinia';
import { defineComponent, onMounted, ref } from 'vue';

import { useEventsStore } from '@/entities/events/eventsStore';

import EventCard from '@/pages/EventsList/EventCard.vue';

export const EventsListPage = defineComponent({
  name: 'EventsListPage',
  components: { EventCard },
  setup() {
    const eventsStore = useEventsStore();
    const { events } = storeToRefs(eventsStore);

    onMounted(() => {
      eventsStore.getEvents();
    });

    const handleBuyTicket = async (
      id: string | number,
      price: number | string
    ) => {
      Modal.confirm({
        title: 'Wanna this? Huh?',
        content: 'Wat?',
        onOk() {
          eventsStore.buyTicket(id, price);
        },
      });
    };

    return {
      events,
      handleBuyTicket,
      formatPrice: eventsStore._yoctoNearToNear,
      selectedKeys: ref<string[]>(['1']),
    };
  },
});
export default EventsListPage;
