
import { defineComponent, onBeforeMount, ref } from 'vue';

import { useNearStore } from '@/entities/nearStore';
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'App',
  setup() {
    const router = useRouter();
    const nearStore = useNearStore();

    onBeforeMount(() => {
      nearStore.init();
    });

    return {
      router,
      selectedKeys: ref<string[]>(['']),
    };
  },
});
