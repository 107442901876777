
import { storeToRefs } from 'pinia';
import { defineComponent } from 'vue';

import { useNearStore } from '@/entities/nearStore';

import ConnectWalletPage from '@/pages/ConnectWalletPage.vue';
import NearIsConnectedPage from '@/pages/NearIsConnectedPage.vue';

export const HomePage = defineComponent({
  name: 'HomePage',
  components: { ConnectWalletPage, NearIsConnectedPage },
  setup() {
    const nearStore = useNearStore();
    const { isSignedIn, inited } = storeToRefs(nearStore);

    return { isSignedIn, inited };
  },
});
export default HomePage;
