
import { storeToRefs } from "pinia";
import { defineComponent, onMounted } from "vue";
import { useRouter } from 'vue-router';

import EventCard from '@/pages/EventsManagement/EventCard.vue'

import { useEventsStore } from "@/entities/events/eventsStore";


export const EventsManagementPage = defineComponent({
  name: 'EventsManagementPage',
  components: { EventCard },
  setup() {
    const router = useRouter();
    const eventsStore = useEventsStore();
    const { ownedEvents } = storeToRefs(eventsStore);

    onMounted(() => {
      eventsStore.getOwnedEvents();
    });

    const handleEditTicket = async (id: string | number) => {
      router.push({ path: `/events-management/${id}` });
    };

    return {
      ownedEvents,
      handleEditTicket,
    };
  },
});
export default EventsManagementPage;
