
import { storeToRefs } from 'pinia';
import { defineComponent, onMounted } from 'vue';

import { useNearStore } from '@/entities/nearStore';

import NearSignOutButton from '@/features/NearSignOutButton.vue';

export const NearIsConnectedPage = defineComponent({
  name: 'NearIsConnectedPage',
  components: { NearSignOutButton },
  setup() {
    const nearStore = useNearStore();
    const { accountId } = storeToRefs(nearStore);

    onMounted(() => {
      nearStore.getAccountId();
    });

    return { accountId };
  },
});
export default NearIsConnectedPage;
