
import { computed, defineComponent, PropType } from 'vue';

import { useEventsStore } from '@/entities/events/eventsStore';
import { NEvent } from '@/entities/events/events.types';

export const EventCard = defineComponent({
  name: 'EventCard',
  props: {
    item: { type: Object as PropType<NEvent>, required: true },
  },
  emits: ['submit'],
  setup(props, { emit }) {
    const eventsStore = useEventsStore();
    const formattedPrice = computed(() =>
      eventsStore._yoctoNearToNear(props.item.price)
    );

    const handleSubmit = () => emit('submit');

    return { formattedPrice, handleSubmit };
  },
});
export default EventCard;
