import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "events-list-page" }
const _hoisted_2 = {
  key: 0,
  class: "events-list-page__list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventCard = _resolveComponent("EventCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, "Events list " + _toDisplayString(_ctx.events.length), 1),
    (_ctx.events.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.events, (item) => {
            return (_openBlock(), _createBlock(_component_EventCard, {
              key: item.token_series_id,
              item: item,
              class: "events-list-page__event-card",
              onSubmit: ($event: any) => (_ctx.handleBuyTicket(item.token_series_id, item.price))
            }, null, 8, ["item", "onSubmit"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}